import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TokenContext from "../TokenContext";
import {
	AppBar,
	Button,
	Toolbar,
	Typography,
	Link,
	Menu,
	MenuItem,
	Fade,
	SwipeableDrawer,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { IsMobile, useStyles } from "../../_services/materialService";
import SecurityIcon from "@mui/icons-material/Security";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HomeIcon from "@mui/icons-material/Home";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { AuthRefreshToken, AuthLogout } from "../../_services/authService";
// import LogoMidge from "../../assets/img/google.svg";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import Flags from "../common/flags";

const components = {
	home: HomeIcon,
	peoplealt: PeopleAltIcon,
	supervidesusercircle: SupervisedUserCircleIcon,
	groupwork: GroupWorkIcon,
	localhospital: LocalHospitalIcon,
	cloudupload: CloudUploadIcon,
	security: SecurityIcon,
	calendar: EventIcon,
	calavailability: EventAvailableIcon,
	default: KeyboardDoubleArrowRightIcon,
};

function AdminToolbar(props) {
	// console.log(props.menu);
	const { t } = useTranslation();
	let history = useHistory();
	const classes = useStyles();
	const isMobile = IsMobile();
	// const istablet = IsTablet();
	//const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
	// const dateshown = useDate();
	//const myToken = useToken();

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const useCounterContext = () => React.useContext(TokenContext);
	let { jwt, assignJwt } = useCounterContext();
	const actualJwt = React.useRef(jwt);

	useEffect(() => {
		const timer880 = setInterval(() => {
			AuthRefreshToken(actualJwt.current)
				.then((response) => {
					actualJwt.current = response;
					assignJwt(response);
				})
				.catch((ex) => {
					//console.log(ex);
					AuthLogout(false);
					props.history.push("/");
				});
		}, 3600 * 1000); //da ripristinare con 880 (meno di 900)
		return () => {
			clearInterval(timer880);
		};
	}, []);
	//menu user
	const [anchorEl, setAnchorEl] = React.useState(null);
	//menu links
	const [anchorMenu, setAnchorMenu] = React.useState([]);
	//customer
	//const customer = ls.getCustomerDescription("INFO");
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickMenu = (event, i) => {
		let newArr = [...anchorMenu];
		newArr[i] = event.currentTarget;
		setAnchorMenu(newArr);
	};

	const handleCloseMenu = () => {
		let newArr = [...anchorMenu];
		for (let i = 0; i < newArr.length; i++) newArr[i] = null;
		setAnchorMenu(newArr);
	};

	const handleNav = (url) => {
		let newArr = [...anchorMenu];
		for (let i = 0; i < newArr.length; i++) newArr[i] = null;
		setAnchorMenu(newArr);
		props.history.push(url);
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const handleLogout = async () => {
		// Logout
		await AuthLogout(true);
		const isHq = localStorage.getItem("isHq") === "true";
		if (isHq) {
			props.history.push("/auth/hqlogin");
		} else {
			props.history.push("/");
		}
		
		//window.location.href = '/';
	};

	const list = (
		anchor //solo per mobile
	) => (
		<div
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List key={"rootList"}>
				{props.menu.map((item, index) => {
					if (item.isMobile === "1") {
						//solo se abilitato a db
						if (item.items) {
							//verifico se son calendari o meno
							if (item.Text === "Calendars") {
								return (
									<>
										<ListItem key={item.Text}>
											<ListItemText primary={t(item.Text)} />
										</ListItem>
										{item.items.map((its) => {
											// console.log(i);
											const TagIcon = !!its.iconCss
												? components[its.iconCss]
												: components["default"];

											if (its.isMobile === "1") {
												return (
													<ListItem
														button
														key={its.id}
														onClick={(e) => handleNav("/app" + its.Path)}
													>
														<ListItemIcon>
															<TagIcon color="primary" />
														</ListItemIcon>
														<ListItemText primary={t(its.Text)} />
													</ListItem>
												);
											} else {
												return null;
											}
										})}
										<Divider />
										{props.calendars.map((icx, idx) => {
											const TagIcon = components["calendar"];
											return (
												<ListItem
													button
													key={"CAL" + idx.toString()}
													onClick={() =>
														handleNav(
															`/app/calendar${
																icx.anaCalendarTypeId === 1 ? "xls" : "free"
															}/${icx.id}`
														)
													}
												>
													<ListItemIcon>
														<TagIcon color="primary" />
													</ListItemIcon>
													<ListItemText primary={t(icx.name)} />
												</ListItem>
											);
										})}
										<Divider />
									</>
								);
							} else {
								return (
									<>
										<ListItem key={item.Text}>
											<ListItemText primary={t(item.Text)} />
										</ListItem>
										{item.items.map((its, idx) => {
											let TagIcon = components[its.iconCss];

											if (its.isMobile === "1") {
												return (
													<ListItem
														button
														key={idx}
														onClick={(e) => handleNav("/app" + its.Path)}
													>
														<ListItemIcon>
															<TagIcon color="primary" />
														</ListItemIcon>
														<ListItemText primary={t(its.Text)} />
													</ListItem>
												);
											}
											return null;
										})}
										<Divider />
									</>
								);
							}
						} else {
							let TagIcon = components[item.iconCss];
							return (
								<>
									<ListItem
										button
										key={item.Text}
										onClick={() => handleNav("/app" + item.Path)}
									>
										<ListItemIcon>
											<TagIcon color="primary" />
										</ListItemIcon>
										<ListItemText primary={t(item.Text)} />
									</ListItem>
									<Divider />
								</>
							);
						}
					}
					return null;
				})}
			</List>

			<List key="btnList">
				<ListItem
					button
					key={t("profile")}
					onClick={() => handleNav("/app/profile")}
				>
					<ListItemIcon>
						<AccountCircleIcon color="primary" />
					</ListItemIcon>
					<ListItemText primary={t("profile")} />
				</ListItem>
				<ListItem
					button
					key={t("logout")}
					onClick={handleLogout}
				>
					<ListItemIcon>
						<ExitToAppIcon color="primary" />
					</ListItemIcon>
					<ListItemText primary={t("logout")} />
				</ListItem>
			</List>
		</div>
	);

	return (
		<React.Fragment>
			<AppBar position="relative">
				<Toolbar
					className={classes.toolbar}
					key="AdminToolbar"
				>
					<Typography
						variant="h6"
						color="secondary"
						noWrap
						className={classes.toolbarTitle}
						onClick={() => history.push("/app")}
					>
						VoluntHUB
					</Typography>
					{!isMobile && (
						<nav key="navbar">
							{props.menu.map((item, index) =>
								item.items ? (
									<>
										<Link
											key={"L1" + index.toString()}
											variant="button"
											color="#ffffff"
											className={classes.link}
											onClick={(e) => handleClickMenu(e, index)}
										>
											{t(item.Text)}
										</Link>
										<Menu
											key={"submenu" + index.toString()}
											id={"submenu" + index.toString()}
											anchorEl={anchorMenu[index]}
											keepMounted
											open={Boolean(anchorMenu[index])}
											onClose={handleCloseMenu}
											TransitionComponent={Fade}
										>
											{item.items.map((its, idx) => (
												<MenuItem
													key={"sm" + idx.toString()}
													onClick={() => handleNav("/app" + its.Path)}
												>
													{t(its.Text)}
												</MenuItem>
											))}
											{item.Text == "Calendars" && (
												<div>
													<div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25" />
													{props.calendars &&
														props.calendars.map((icx, idx) => (
															<MenuItem
																key={"CAL" + idx.toString()}
																onClick={() =>
																	handleNav(
																		`/app/calendar${
																			icx.anaCalendarTypeId == 1
																				? "xls"
																				: "free"
																		}/${icx.id}`
																	)
																}
															>
																{icx.name}
															</MenuItem>
														))}
												</div>
											)}
										</Menu>
									</>
								) : (
									<Link
										key={"L2" + index.toString()}
										variant="button"
										color="#ffffff"
										className={classes.link}
										onClick={() => handleNav("/app" + item.Path)}
									>
										{t(item.Text)}
									</Link>
								)
							)}
						</nav>
					)}

					<section
						className={classes.rightToolbar}
						key="sec-simple-menu"
					>
						{/* <span >
                            {!istablet && dateshown}
                        </span> */}
						{!isMobile && (
							<>
								{/* <Flags /> */}
								{/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={() => history.push("/helpcenter")}>
                                    <Tooltip title={t('helpcenter')}>
                                        <HelpOutlineIcon color='secondary' fontSize="large" />
                                    </Tooltip>
                                </Button> */}
								<Button
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={handleClick}
								>
									<AccountCircleIcon
										color="secondary"
										fontSize="large"
									/>
								</Button>
								<Menu
									key="simple-menu"
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
									TransitionComponent={Fade}
								>
									<MenuItem
										key={"profile"}
										onClick={() => handleNav("/app/profile")}
									>
										{t("profile")}
									</MenuItem>
									<MenuItem
										key={"logout"}
										onClick={handleLogout}
									>
										{t("logout")}
									</MenuItem>
								</Menu>
							</>
						)}
						{isMobile && (
							<div>
								<Button onClick={toggleDrawer("right", true)}>
									<MenuIcon
										sx={{ color: "#ffffff" }}
										fontSize="large"
									/>
								</Button>
								<SwipeableDrawer
									//disableBackdropTransition={!iOS}
									//disableDiscovery={iOS}
									color="#ffffff"
									anchor={"right"}
									open={state["right"]}
									onClose={toggleDrawer("right", false)}
									onOpen={toggleDrawer("right", true)}
								>
									{list("right")}
								</SwipeableDrawer>
							</div>
						)}
					</section>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

export default AdminToolbar;
