import React, { Suspense, Component } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import { createBrowserHistory } from "history";
import CacheBuster from "./CacheBuster";
import { auth } from "./_services/authService";
import Admin from "layouts/Admin.js";
import Login from "views/auth/Login.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// views without layouts
import Landing from "views/Landing.js";

//import HomeLand from './HomeLand';
//import Login from './Login';
import { unregister } from "./serviceWorker";
import i18next from "i18next";
import { LoadingPage } from "views/pages";
import "./App.css";
import * as cagregorian from "cldr-data/main/it/ca-gregorian.json";
import * as currencies from "cldr-data/main/it/currencies.json";
import * as timeZoneNames from "cldr-data/main/it/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as numbers from "cldr-data/main/it/numbers.json";
import EJ2_LOCALE from "../node_modules/@syncfusion/ej2-locale/src/it.json";

loadCldr(cagregorian, currencies, numbers, timeZoneNames, numberingSystems);
L10n.load({ it: EJ2_LOCALE.it });
unregister();

const history = createBrowserHistory();
const theme = createTheme({
	palette: {
		primary: {
			light: "#cad2c5",
			main: "#2c5282",
			//main: '#2c5282',
			dark: "#354f52",
			contrastText: "#2f3e46",
			//main: blue[500],
		},
		secondary: {
			main: "#ffffff",
		},
	},
	typography: {
		allVariants: {
			color: "#2c5282",
		},
	},
});

class App extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.router = null;
		this.state = {
			isAuthenticated: false,
			isLogin: false,
			user: {},
		};
		this.getRouteRef = (r) => {
			this.router = r;
		};
	}

	async componentDidMount() {
		try {
			const user = auth.getCurrentUser();
			//console.log("User: ", user);
			if (user) {
				this.setState({ user: user, isAuthenticated: true });
			}
			if (localStorage.getItem("weblang") == null)
				localStorage.setItem("weblang", "it");
			import(
				"../node_modules/@syncfusion/ej2-locale/src/" +
					localStorage.getItem("weblang") +
					".json"
			).then((module) => {
				switch (localStorage.getItem("weblang")) {
					case "it":
						L10n.load({ it: module.it });
						break;
					case "es":
						L10n.load({ es: module.es });
						break;
					case "en":
						L10n.load({ en: module.en });
						break;
					default:
						L10n.load({ it: module.it });
				}
			});
		} catch (e) {
			if (e !== "No current user") {
				// alert(e);
			}
		}
		// this.setState({ isAuthenticating: false });
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleLogin = async (event) => {
		// Richiama il form di Login
		this.setState({ isLogin: true });
	};

	render() {
		i18next.changeLanguage(localStorage.getItem("weblang"));
		return (
			<CacheBuster>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return null;
					if (!loading && !isLatestVersion) {
						refreshCacheAndReload();
					}
					return (
						<Suspense
							fallback={
								<ThemeProvider theme={theme}>
									<LoadingPage />
								</ThemeProvider>
							}
						>
							{" "}
							{/* creare componente con Logo Volunthub */}
							<ThemeProvider theme={theme}>
								<Router
									history={history}
									ref={this.getRouteRef}
								>
									<Switch>
										{/* add routes with layouts */}
										<Route
											path="/app"
											render={(props) => <Admin {...props} />}
										/>
										<Route
											path="/auth/login"
											exact
											component={(props) => <Login {...props} isHq={false}/>}
										/>
										<Route
											path="/auth/hqlogin"
											exact
											component={(props) => <Login {...props} isHq={true}/>}
										/>
										{/* add routes for landing page*/}
										<Route
											path="/"
											exact
											component={Landing}
										/>
										{/* add redirect for first page */}
										<Redirect to="/app/home" />
									</Switch>
								</Router>
							</ThemeProvider>
						</Suspense>
					);
				}}
			</CacheBuster>
		);
	}
}

export default App;
